.loader {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	vertical-align: middle;
}

.loader-quart {
	border-radius: 50px;
	border: 6px solid rgba(255, 255, 255, 0.4);
}

.loader-quart:after {
	content: '';
	position: absolute;
	top: -6px;
	left: -6px;
	bottom: -6px;
	right: -6px;
	border-radius: 50px;
	border: 6px solid rgba(37, 37, 37, 0.233);
	border-top-color: rgb(37, 37, 37);
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		tranform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		tranform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.anchorTag {
	text-decoration: none !important;
	color: rgb(31, 135, 231);
	cursor: pointer;
}

.text-center {
	text-align: center;
}

.footer {
	position: absolute;
	height: 30px;
	text-align: center;
	padding: 5px 14px;
	background: black;
	color: white;
	font-weight: 600;
	bottom: 0px;
	width: 100%;
}